<template>
    <div class="claim_main">
        <div class="header" v-if="y > 20">
            <div class="header_container_fix">
                <div class="header_logo" @click="$router.push('/')">
                    <img  src="../../assets/img/home/about_header.png" height="39" width="135"/>
                </div>
                <span class="header_right">
                    <span @click="$router.push('/aboutUs')">关于我们</span>
                    <div class="logo_btn" @click="handleLogin">登录 | 注册</div>
                </span>
            </div>
        </div>
        <div class="header_container">
            <div class="header_logo">
                <img @click="$router.push('/')"  src="../../assets/img/home/home_logo_white.png" height="39" width="135"/>
            </div>
            <span class="header_right">
                        <span @click="$router.push('/aboutUs')">关于我们</span>
                        <div class="logo_btn" @click="handleLogin">登录 | 注册</div>
                </span>
        </div>
        <div class="main_text">会员权限</div>
        <div class="claim_table">
            <table>
                <tr class="table_head">
                    <th class="column1" style="align-self: flex-end; margin-bottom: 14px">产品功能</th>
                    <th class="column2"><img src="../../assets/img/home/vip_claim.png" height="126" width="308"/></th>
                    <th class="column2"><img src="../../assets/img/home/svip_claim.png" height="126" width="308"/></th>
                </tr>
                <tr class="has_bg">
                    <td class="column1">无限制广告创意搜索查看</td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                </tr>
                <tr>
                    <td class="column1">无限制竞品公司查看</td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                </tr>
                <tr class="has_bg">
                    <td class="column1">竞品广告数据查看</td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                </tr>
                <tr>
                    <td class="column1">竞品推广策略分析</td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                    <td class="column2"><img src="../../assets/img/home/has_claim.png" height="30" width="30"/></td>
                </tr>
                <tr class="has_bg">
                    <td class="column1">公司/关键词监测</td>
                    <td class="column2"><span>VIP特权</span></td>
                    <td class="column2"><span>SVIP特权</span></td>
                </tr>
                <tr>
                    <td class="column1">数据导出</td>
                    <td class="column2"><span>VIP特权</span></td>
                    <td class="column2"><span>SVIP特权</span></td>
                </tr>
                <tr class="has_bg">
                    <td class="column1">默认子账号数量</td>
                    <td class="column2"><span>0</span></td>
                    <td class="column2"><span>1</span></td>
                </tr>
                <tr>
                    <td class="column1">价格</td>
                    <td class="column2"><span class="price">￥<span>4980</span>/年</span></td>
                    <td class="column2"><span class="price">￥<span>19800</span>/年</span></td>
                </tr>
            </table>
        </div>
        <div class="bottom">
            <span>Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投（aijingtou.com）. All Rights Reserved. </span>
            <span class="link" @click="toLink">沪ICP备12039960号-38</span>
        </div>
    </div>
</template>

<script>
  import PinganAna from "pingansec-vue-ana";
  import {
    get_user_info
  } from '@/api/common'

  export default {
    name: "claim",
    data() {
      return {
        y: 0
      }
    },
    mounted() {
      document.querySelector('body').onscroll = this.handleScroll;
    },
    methods: {
      handleLogin() {
        PinganAna.fire(3035)
        get_user_info().then(res => {
          if(res.data.result_code == 0){
            this.$router.push('/box/dataView');
          }
        })
      },
      toLink() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
      },
      handleScroll(e) {
        this.y = e.target.scrollingElement.scrollTop;
      },
    }
  }
</script>

<style scoped lang="scss">
.claim_main {
    width: 100%;
    height: 100%;
    background: url("../../assets/img/home/claim_bg.png") no-repeat;
    background-size: contain;
    text-align: center;
    .header {
        width: 100%;
        height: 70px;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(235, 239, 242, 0.5);
        position: fixed;
        top: 0;
        z-index: 10 ;
        .header_container_fix {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            width: 1200px;
            margin: 0 auto;
            .header_logo {
                position: relative;
            }
            .header_right {
                color: #444444;
                font-size: 16px;
                .logo_btn {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 90px;
                    height: 33px;
                    background-color: #ffffff;
                    border-radius: 6px;
                    border: solid 1px #318bf9;
                    box-sizing: border-box;
                    font-size: 14px;
                    cursor: pointer;
                    color: #1f81f8;
                    &:hover {
                        background-color: #e9f3fd;
                    }
                    &:active {
                        background-color: #f6f9fe;
                    }
                }
                span {
                    margin: 0 39px;
                    cursor: pointer;
                }
            }
        }
    }
    .header_container {
        width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(31, 129, 248, 0);
        margin: 0 auto;
        position: relative;
        top: 28px;
        .header_logo {
            position: relative;
            img {
                cursor: pointer;
            }
        }
        .header_right {
            width: 191px;
            position: relative;
            color: #ffffff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo_btn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 90px;
                height: 33px;
                border-radius: 6px;
                border: solid 1px #ffffff;
                box-sizing: border-box;
                font-size: 14px;
                cursor: pointer;
            }
            span {
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
    .main_text {
        font-size: 45px;
        text-align: center;
        color: #ffffff;
        margin-top: 102px;
    }
    .claim_table {
        padding: 50px;
        background-color: #fff;
        border-radius: 6px;
        display: inline-block;
        margin-top: 68px;
        margin-bottom: 104px;
        table {
            text-align: center;
            font-size: 18px;
            .table_head {
                font-size: 18px;
                color: #999999;
                img {
                    width: 308px;
                    height: 126px;
                }
            }
            .has_bg {
                background-color: #f4f7fc;
            }
            .column1 {
                width: 354px;
                text-align: left;
                padding-left: 39px;
            }
            .column2 {
                width: 355px;
                position: relative;
                right: 20px;
            }
            .price {
                color: #ff6000;
                span {
                    font-size: 30px;
                }
            }
            tr {
                min-height: 77px;
                display: flex;
                align-items: center;
            }

        }
    }
    .bottom {
        width: 100%;
        height: 75px;
        text-align: center;
        color: #999999;
        font-size: 14px;
        line-height: 75px;
        .link {
            cursor: pointer;
        }
    }
}
</style>
